import { get, post } from "../axios";

const getDiagnosisForm = () => get("/get_questions");
const getDiagnosis = (payload) => post("get_diagnosis", payload);

const diagnosis = {
    getDiagnosisForm,
    getDiagnosis,
};

export default diagnosis;
