import React from "react";
import SectionHeading from "../SectionHeading";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import Testimonial from "../Testimonial";

const data = [
    {
        img: "images/leo.png",
        name: "Leonardo Aranguiz",
        designation: "Odontologo & CEO",
        subTitle: "",
    },
    {
        img: "images/diego.png",
        name: "Diego Robles",
        designation: "Product developer",
        subTitle: "",
    },
    {
        img: "images/esteban.png",
        name: "Esteban Ortiz",
        designation: "Full stack developer",
        subTitle: "",
    },
];

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
        {...props}
        className={
            "slick-arrow-left slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
    >
        <Icon icon="fa-solid:angle-left" />
    </div>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
        {...props}
        className={
            "slick-arrow-right slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
        <Icon icon="fa-solid:angle-right" />
    </div>
);

const settings = {
    dots: false,
    infinite: true,
    loop: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                dots: true,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                dots: true,
            },
        },
    ],
};

const TestimonialSlider = () => {
    return (
        <section id="team">
            <div className="st-height-b120 st-height-lg-b80" />
            <SectionHeading
                title="Nuestro equipo"
                subTitle="Conformado por profesionales, cada uno muy inmerso en el desarrollo de producto y emprendimiento."
            />
            <div className="container">
                <Slider {...settings} className="st-slider-style2">
                    {data.map((elements, index) => (
                        <Testimonial {...elements} key={index} />
                    ))}
                </Slider>
            </div>
            <div className="st-height-b120 st-height-lg-b80" />
        </section>
    );
};

export default TestimonialSlider;
