import React from "react";

const data = [
    {
        bg: "green",
        icon: "icons/icon2.svg",
        title: "Servicio 24 horas",
        subTitle:
            "Resuelve tus consultas dentales en cualquier momento con nuestro formulario interactivo. Es rápido y fácil de usar!",
    },
    {
        bg: "purple",
        icon: "icons/icon1.svg",
        title: "Doctores calificados",
        subTitle:
            "Conéctate con dentistas especialistas a través de videollamadas. Recibe asesoramiento profesional y personalizado,",
    },
    {
        bg: "red",
        icon: "icons/icon3.svg",
        title: "Emergencias",
        subTitle:
            "Obtén un diagnóstico preliminar y recibe recetas de medicamentos o licencias cuando sea necesario, sin salir de casa",
    },
];

const Iconbox = ({ varient }) => {
    return (
        <section>
            <div className="st-height-b120 st-height-lg-b80" />
            <div className="container">
                <div className="row">
                    {data.map((element, index) => (
                        <div className="col-lg-4" key={index}>
                            <div className={`st-iconbox st-style1 ${varient}`}>
                                <div
                                    className={`st-iconbox-icon st-${element.bg}-box`}
                                >
                                    <img
                                        src={element.icon}
                                        alt={element.icon}
                                    />
                                </div>
                                <h2 className="st-iconbox-title">
                                    {element.title}
                                </h2>
                                <div className="st-iconbox-text">
                                    {element.subTitle}
                                </div>
                            </div>
                            <div className="st-height-b0 st-height-lg-b30" />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Iconbox;
