import axios from "axios";

// const DEV_URL = "http://127.0.0.1:8050";
const PROD_URL = "https://odontify-gpt.onrender.com/";

const BASE_URL = PROD_URL;

const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

const get = (param) => api.get(param);
const post = (url, payload) => api.post(url, payload);

export { get, post };
