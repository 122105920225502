import React, { useState } from "react";

const Question = ({ question, onAnswerSelected }) => {
    return (
        <div>
            <h3>{question.label}</h3>
            <div>
                {question.answers.map((answer) => (
                    <button
                        className="st-btn st-style1 st-color1 st-smooth-move"
                        style={{ margin: 5 }}
                        key={answer.id}
                        onClick={() => onAnswerSelected(answer)}
                    >
                        {answer.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

const DiagnosisForm = ({ loading, data, onAnswereSelect, onFinish }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    if (loading) return <p>Loading...</p>;

    const currentQuestion = data[0]?.questions[currentQuestionIndex];

    const handleAnswerSelected = (answer) => {
        const answerData = {
            questionId: currentQuestion.id,
            questionLabel: currentQuestion.label,
            answerId: answer.id,
            answerLabel: answer.label,
            answerDescription: answer.description,
        };

        onAnswereSelect(answerData);

        if (currentQuestionIndex + 1 < data[0].questions.length) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            onFinish(true);
        }
    };

    return (
        <div>
            <h2>{data[0].label}</h2>
            <Question
                question={currentQuestion}
                onAnswerSelected={handleAnswerSelected}
            />
        </div>
    );
};

export default DiagnosisForm;
