import React from "react";
import Cal from "@calcom/embed-react";

export default function App() {
    return (
        <div className="App">
            <Cal
                calLink="leonardo-aranguiz-jkkpp2"
                config={{ theme: "light" }}
            ></Cal>
        </div>
    );
}
