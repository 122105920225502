import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import Modal from ".";
import DiagnosisForm from "../Forms/DiagnosisForm";
import { diagnosis } from "../../api";

const DiagnosisModal = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [data, setData] = useState(null);
    const [answers, setAnswers] = useState([]);

    const [diagnosisAnswere, setDiagnosisAnswere] = useState(null);
    const [isEnd, setIsEnd] = useState(false);

    useEffect(() => {
        diagnosis
            .getDiagnosisForm()
            .then(({ data }) => {
                setLoading(false);
                setData(data.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }, []);

    const getDiagnosis = async () => {
        const payload = {
            answers,
        };

        setLoading(true);
        try {
            const { data } = await diagnosis.getDiagnosis(payload);
            setDiagnosisAnswere(data.data);
        } catch (error) {
            console.log(error);
        }

        setLoading(false);
    };

    const handleAnswereSelect = (answere) => {
        setAnswers([...answers, answere]);
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setAnswers([]);
        setIsEnd(false);
        setDiagnosisAnswere(null);
        setIsModalOpen(false);
    };

    return (
        <>
            <button
                onClick={openModal}
                className="st-btn st-style1 st-color1 st-smooth-move"
            >
                Diagnóstico
            </button>

            <Modal isOpen={isModalOpen} onClose={closeModal}>
                {!diagnosisAnswere ? (
                    <>
                        {!isEnd ? (
                            <DiagnosisForm
                                data={data}
                                loading={loading}
                                onAnswereSelect={handleAnswereSelect}
                                onFinish={setIsEnd}
                            />
                        ) : (
                            <button
                                className="st-btn st-style1  st-smooth-move"
                                onClick={getDiagnosis}
                                disabled={loading}
                            >
                                Diagnosticar
                            </button>
                        )}
                    </>
                ) : (
                    <>
                        <p className="st-hero-subtitle">{diagnosisAnswere}</p>
                        <button
                            className="st-btn st-style1 st-color1 st-smooth-move"
                            onClick={() => {
                                navigate("/booking");
                            }}
                        >
                            Agendar Consulta online
                        </button>
                    </>
                )}
            </Modal>
        </>
    );
};

export default DiagnosisModal;
